import axios from 'axios';
import util from './util.service';
import store from '../store/store';

// Axios Settings 
axios.defaults.withCredentials = true;

/* eslint-disable */
const PAGE_SIZE = 99;
const BASE_URL = '__API_URL__' || window.location.origin; // __API_URL__ from .env and replaced at buildtime

var state = {
    about: {},
    BASE_URL: BASE_URL
};

const get = (url, options = {}) => {
    return axios({
        method: 'get',
        url,
        withCredentials: true,
        crossDomain: true,
        ...options
    });
};
const patch = (url, payload = {}, options = {}) => {
    return axios({
        method: 'patch',
        url,
        data: payload,
        withCredentials: true,
        crossDomain: true,
        ...options
    });
};
const post = (url, payload = {}, options = {}) => {
    return axios({
        method: 'post',
        url,
        data: payload,
        withCredentials: true,
        crossDomain: true,
        ...options
    });
};
const put = (url, payload = {}, options = {}) => {
    return axios({
        method: 'put',
        url,
        data: payload,
        withCredentials: true,
        ...options
    });
};
const del = (url, options = {}) => {
    return axios({
        method: 'delete',
        url,
        ...options
    });
};

/**
 * Initial function that retrieve data required by other requests
 * @return [data, error]
 */
const init = (() => {
    let promise = null;
    return async () => {
        promise = promise || getAbout();
        try {
            return [await promise, false];
        } catch (error) {
            store.dispatch("error/setError", {message: error.message});
            return [undefined, error];
        }
    };
})();

/**
 * Return about response or throw an error
 * @returns {Promise<object>} about reponse
 */
const getAbout = async () => {
    let response = await get(`${state.BASE_URL}/auth/about`, {responseType: 'json'});
    if (util.isAboutValid(response.data)) {
        state.about = response.data;
        state.BASE_URL = `${state.BASE_URL}/api/${state.about.tableau_server.rest_api_version}`;
        return state.about;
    } else {
        throw new Error('User was not found on associated Tableau site. Logout and signin as existing user.');
    }
}

/**
 * Request to get list of favorite entities
 * @param {string} entity Type of favorite entity: view, workbook
 * @returns {Promise<object[]>} list of favorite entities
 */
// const getFavorites = async entity => {
//     let [data, error] = await init();
//     if (error) return [];

//     try {
//         let response = await get(`${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/favorites/${state.about.payload.tableau_site_user_id}`);
//         return response.data.favorites.favorite.filter(fav => fav[entity]);

//     } catch (error) {
//         console.error('Error getting favorites', response);
//         return [];
//     }
// }

/**
 * Get list of workbooks
 * @param {object} filters filters for workboos
 * @param {string[]} filters.tags tags of workbook to include
 * @param {number} pageNumber pagination page number
 * @param {number} pageSize pagination page size
 * @param {object[]} result initial array of workbooks
 * @returns {object[]} list of worbooks
 */
const getWorkbooks = async (
    {tags = []} = {},
    pageNumber = 1,
    pageSize = PAGE_SIZE,
    result = []
) => {
    let [data, error] = await init();
    if (error) return {workbook: result, pagination: {pageNumber, pageSize, totalAvailable: 0}};

    const url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/workbooks?sort=name:asc&pageSize=${pageSize}&pageNumber=${pageNumber}`;

    // Add tags filter
    let compactTags = tags.filter(tag => tag);
    if (compactTags.length) {
        url += `&filter=tags:in:[${compactTags.join(',')}]`;
    }
    // Get favorites workbooks
    // let favorites = await getFavorites('workbook');
    // let favoriteWorkbookIds = favorites.map(fav => fav.workbook.id);

    // Request
    let response = await get(url);
    const {workbooks, pagination} = response.data;
    workbooks.workbook.forEach((workbook) => {
        // workbook.isFavorite = favoriteWorkbookIds.includes(workbook.id);
        workbook.isWorkbook = true; // Differentiate between workbooks and projects
        workbook.parentProjectId = workbook?.project.id || null;
        workbook.showTabs = workbook.showTabs === 'true'; // Cast string boolean to actual boolean
        result.push(workbook);
    });
    return {workbooks: result, pagination};
}

/**
 * Return list of views for specified workbook
 * @param {object} options 
 * @param {object} options.workbook Workbook model, should have 'id'
 * @returns {Promise<object[]>} list of views
 */
const getViewsForWorkbook = async ({workbook}) => {
    let [data, error] = await init();
    if (error) return [];

    let url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/workbooks/${workbook.id}/views`;
    let repsponse = await get(url);
    let views = repsponse.data.views.view.map(view => {
        view.url = util.buildViewUrl(BASE_URL, state.about.tableau_server.site_content_url, view.contentUrl);
        return view;
    })
    return views;
}

/**
 * Request to add workbook to favorite
 * @param {object} options 
 * @param {object} options.workbook Work model, require 'id'
 * @returns api response
 */
const addWorkbookToFavorite = async ({workbook}) => {
    let [data, error] = await init();
    if (error) return;
    const {tableau_server, payload} = state.about;

    const url = `${state.BASE_URL}/sites/${tableau_server.site_id}/favorites/${payload.tableau_site_user_id}`;
    const body = {favorite: {label: workbook.id, workbook: {id: workbook.id}}};

    try {
        let response = await put(url, body);
        return response;
    } catch (e) {
        console.error('Error adding workbook to favorite', e);
        return Promise.reject(e);
    }
}

/**
 * Remove workbook from favorite
 * @param {object} options 
 * @param {object} options.workbook Work model, require 'id'
 * @returns api response
 */
const deleteWorkbookFromFavorite = async ({workbook}) => {
    let [data, error] = await init();
    if (error) return;

    const {tableau_server, payload} = state.about;
    const url = `${state.BASE_URL}/sites/${tableau_server.site_id}/favorites/${payload.tableau_site_user_id}/workbooks/${workbook.id}`;

    try {
        let response = await del(url);
        return response;
    } catch (e) {
        // TODO: Is 'e' an html/xml response?
        return Promise.reject(`${e.querySelector('summary').textContent}: ${e.querySelector('detail').textContent}`);
    }
}

/**
 * Get view request
 * @param {object} options 
 * @param {object} options.name view name
 * @returns {Promise<object>} view response
 */
const getView = async ({name} = {}) => {
    let [data, error] = await init();
    if (error) return;
    const {site_id, site_content_url} = state.about.tableau_server;
    const getViewUrl = `${state.BASE_URL}/sites/${site_id}/views?filter=viewUrlName:eq:${name}`;

    try {
        let response = await get(getViewUrl);
        const view = response.data.views.view[0];
        if (view) {
            view.url = util.buildViewUrl(BASE_URL, site_content_url, view.contentUrl);
        }
        return view || false;
    } catch (e) {
        console.error(`Error getting view ${name}`, e);
        return false;
    }
};


/**
 * Get list of views
 * @param {object} filters 
 * @param {string[]} filters.tags list of tags to include view
 * @param {string} filters.sort sort view
 * @param {number} pageNumber pagination page number
 * @param {number} pageSize pagination page size
 * @param {object[]} views initial array of workbooks
 * @returns {Promise<object>} return list of views and pagination data
 */
const getViews = async (
    {tags = [], sort = 'name:asc'} = {},
    pageNumber = 1,
    pageSize = PAGE_SIZE,
    views = []
) => {
    let [data, error] = await init();
    if (error) return {views: [], pagination: {pageNumber, pageSize, totalAvailable: 0}};

    const {site_content_url, rest_api_version, site_id} = state.about.tableau_server;
    // let favorites = await getFavorites('view');
    // let favoritesIdMap = favorites.map(fav => fav.view.id);

    let getViewUrl = `${state.BASE_URL}/sites/${site_id}/views?sort=${sort}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    tags = tags.filter(tag => tag.length); // Filter empty tags
    if (tags.length) {
        getViewUrl += `&filter=tags:in:[${tags.join(',')}]`;
    }

    try {
        let response = await get(getViewUrl);
        if (response.data.views.view) {
            response.data.views.view.forEach(function (view) {
                if (!views.find(v => v.id === view.id)) {
                    view.isView = true; // Differentiate between dashboards and projects
                    view.parentProjectId = view?.project.id || null;
                    view.url = util.buildViewUrl(BASE_URL, site_content_url, view.contentUrl);
                    // view.isFavorite = favoritesIdMap.includes(view.id);
                    view.previewImage = BASE_URL + '/api/' + rest_api_version + '/sites/' + site_id + '/workbooks/' + view.workbook.id + '/views/' + view.id + '/previewImage'
                }
            });
            views.push(...response.data.views.view);
        }
        let pagination = response.data.pagination;

        return {views, pagination};
    } catch (e) {
        console.error('Error getting views', e);
    }
};

/**
 * Add view to favorite
 * @param {string} viewId view of id
 * @returns api response
 */
const addViewToFavorites = async (viewId) => {
    let [data, error] = await init();
    if (error) return;

    const {tableau_server, payload} = state.about;

    const url = `${state.BASE_URL}/sites/${tableau_server.site_id}/favorites/${payload.tableau_site_user_id}`;
    const body = {favorite: {label: viewId, view: {id: viewId}}};

    try {
        let response = await put(url, body);
        return response;
    } catch (e) {
        console.error('Error adding view to favorite', response);
        return Promise.reject(`${response.summary}: ${response.detail}`);
    }
};

/**
 * Remove view from favorite
 * @param {string} viewId view of id
 * @returns api response
 */
const deleteViewFromFavorites = async (viewId) => {
    let [data, error] = await init();
    if (error) return;
    const {tableau_server, payload} = state.about;

    const url = `${state.BASE_URL}/sites/${tableau_server.site_id}/favorites/${payload.tableau_site_user_id}/views/${viewId}`;
    try {
        let response = await del(url, {});
        return response;
    } catch (e) {
        console.error('Error removing view from favorite', response);
        return Promise.reject(`${xmlResponseDoc.querySelector('summary').textContent}: ${xmlResponseDoc.querySelector('detail').textContent}`);
    }
};

/**
 * Return list of projects
 * @returns {Promise<object[]>} list of projects
 */
const getProjects = async () => {
    let [data, error] = await init();
    if (error) return [];

    let url = `${state.BASE_URL}/sites/${state.about.tableau_server.site_id}/projects`;
    try {
        let response = await get(url);
        response.data.projects.project.forEach(p => p.isProject = true);
        return response.data.projects.project;
    } catch (e) {
        console.error('Error getting Projects', e);
    }
}

/**
 * Get users data
 * @returns user's data
 */
const getUser = async () => {
    try {
        let response = await get('/auth/me');
        return response.data;
    } catch (e) {
        console.error('Error getting user data', e);
    }
}

/**
 * Update user's data
 * @param {object} user 
 * @param {string} user.fullname user fullname 
 * @param {string} user.fullname user email 
 * @returns api response
 */
const updateUser = async ({fullname, email}) => {
    try {
        let response = await patch('/auth/me', {fullname, email});
        return response
    } catch (e) {
        console.error('Error updating user data', e);
    }
}

/**
 * Update users password
 * @param {string} currentPassword current password
 * @param {string} newPassword new password
 * @returns api response
 */
const updatePassword = async (currentPassword, newPassword) => {
    try {
        let response = await post(`/auth/passwd`, {
            'old_password': currentPassword,
            'new_password': newPassword
        });
        return response;
    } catch (e) {
        console.error('Error updating password', e);
    }
};

/**
 * Login users
 * @param {string} user username
 * @param {string} pass password
 * @param {string} tableauUrl Tableau server url
 * @param {string} tableauSite Tableau site name
 * @returns response and redirection location
 */
const login = async (user, pass, tableauUrl, tableauSite) => {
    const url = window.location.search ? `/login${window.location.search}` : `/login`;
    const formData = new FormData();

    // Vault support
    const urlParams = new URLSearchParams(window.location.search);
    let loc = urlParams.get('location');
    if (loc) {
        formData.append('location', loc);
    }

    formData.append('username', user);
    formData.append('password', pass);
    if (tableauUrl) {
        formData.append('tableau_server_url', tableauUrl);
    }
    if (tableauSite) {
        formData.append('tableau_server_site', tableauSite);
    }
    const response = await post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});

    return {response, location: loc || '/'}
};

/**
 * Logout user by redirecting to logout route
 */
const logout = () => {
    window.location = '/logout';
};

/**
 * Get zconfig file
 * @returns zconfig object
 */
const getConfig = async () => {
    try {
        let config = await getLocalFile('/zconfig.json');
        if (typeof config !== 'object') {
            console.error('Error parsing zconfig.json', config);
        }
        return config;
    } catch (e) {
        console.error('Error getting zconfig.json file', e);
        resolve({});
    }
};

/**
 * Update zconfig.json file
 * @param {object} config model to save as zconfig.json
 * @returns api response
 */
const saveConfig = async (config) => {
    const blob = new Blob([JSON.stringify(config, null, 4)], {
        type: 'text/plain'
    });
    const data = new FormData();
    data.append('file', blob, 'zconfig.json');
    try {
        let response = await axios({
            method: 'POST',
            url: '/admin/edit/',
            data: data
        });
        return response;
    } catch (e) {
        console.error('Error saving config', error);
    }
};

/**
 * Request local file
 * @param {string} filename name of file
 * @returns 
 */
const getLocalFile = async (filename) => {
    try {
        let response = await get(filename);
        return response.data;
    } catch (e) {
        console.error(`Error getting ${filename}`, e);
    }
};

/**
 * Get app templates
 * @param {object} options 
 * @param {string} repo github repository name
 * @param {string} branch github repository branch
 * @returns {Promise<object[]>} list of templates
 */
const getAppTemplates = async ({repo, branch}) => {
    let url = `https://api.github.com/repos/${repo}/contents/app`;
    if (branch) {
        url = `${url}?ref=${branch}`;
    }
    let response = await get(url, {withCredentials: false});
    return response.data;
}

/**
 * Get login templates
 * @param {object} options 
 * @param {string} repo github repository name
 * @param {string} branch github repository branch
 * @returns {Promise<object[]>} list of templates
 */
const getLoginTemplates = async ({repo, branch}) => {
    let url = `https://api.github.com/repos/${repo}/contents/login`;
    if (branch) {
        url = `${url}?ref=${branch}`;
    }
    let response = await get(url, {withCredentials: false});
    return response.data;
}

/**
 * Get template's screenshots
 * @param {object} options 
 * @param {string} repo github repository name
 * @returns 
 */
const getTemplateScreenshots = async ({repo}) => {
    let result = {}; // template.name: blob
    let response = await get(`https://api.github.com/repos/${repo}/contents/screenshots`, {withCredentials: false});
    response.data.forEach(file => {
        let namePieces = file.name.split('.');
        namePieces.splice(namePieces.length - 1, 1);
        let templateName = namePieces.join('.');
        result[templateName] = file.download_url;
    });
    return result;
};

/**
 * Download template
 * @param {string} templateUrl 
 * @returns template
 */
const getTemplate = async (templateUrl) => {
    let response = await get(templateUrl, {withCredentials: false});
    return response.data;
}

/**
 * Save template
 * @param {string} contents file content
 * @param {string} filename file name
 * @returns 
 */
const saveTemplate = async (contents, filename) => {
    const blob = new Blob([contents], {
        type: 'text/plain'
    });
    const data = new FormData();
    data.append('file', blob, filename);
    try {
        let response = await axios({
            method: 'POST',
            url: '/admin/edit/',
            data: data
        });
        return response;
    } catch (e) {
        console.error('Error saving template', error);
    }
}

/**
 * Upload file
 * @param {object} file file to upload
 * @returns api response
 */
const upload = async (file) => {
    const data = new FormData();
    const uploadedFilename = `/assets/${file.name}`;
    data.append('file', file, uploadedFilename);
    let response = await axios({
        method: 'POST',
        url: '/admin/edit/',
        data: data
    });
    return response;
}

export {
    init,
    getConfig,
    getTemplate,
    getAppTemplates,
    getLoginTemplates,
    getTemplateScreenshots,
    getLocalFile,
    getWorkbooks,
    getViewsForWorkbook,
    addWorkbookToFavorite,
    deleteWorkbookFromFavorite,
    getView,
    getViews,
    getProjects,
    saveConfig,
    saveTemplate,
    addViewToFavorites,
    deleteViewFromFavorites,
    getUser,
    updateUser,
    updatePassword,
    upload,
    logout,
    login
};
