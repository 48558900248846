<template>
    <div class="z-error" v-if="hasError">
        {{ message }}
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import store from '../store/store';
/**
 * Component displays error message, to help inform user about potential issues.
 * @tag z-error
 */
export default {
    name: 'Error',
    store,
    props: {},
    computed: {
        ...mapState('error', ['message']),
        ...mapGetters('error', ['hasError'])
    }
};
</script>

<style>
.z-error {
    color:red;
    text-align: center;
}
</style>
