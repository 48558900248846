export function projectSort (a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

export function viewSort (a, b) {
    // if (a.isFavorite && !b.isFavorite) {
    //     return -1;
    // }
    // if (b.isFavorite && !a.isFavorite) {
    //     return 1;
    // }

    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

export function generateProjectTree (item, data) {
    if (item.isView || item.isWorkbook) {
        return item;
    }
    const childViews = item.children || [];
    const childProjects = data
        .filter(d => d.parentProjectId === item.id) // Only views/projects that are in this project
        .map(d => generateProjectTree(d, data)) // Generate subtree
        .filter(d => d.isView || d.isWorkbook || d.children); // Only views and projects that have children
    const children = [...childViews, ...childProjects].sort(viewSort);
    return children.length ? {...item, children} : item;
}

export function removeDuplicates (array) {
    for (let i = array.length - 1; i >= 0; i--) {
        let otherInstanceIndex = array.findIndex(function (el) {
            return el.id === array[i].id;
        });
        if (otherInstanceIndex !== i && otherInstanceIndex !== -1) {
            array.splice(i, 1);
        }
    }
}

export const buildViewUrl = (baseUrl, siteContentUrl, contentUrl) => {
    const newContentUrl = contentUrl.replace(/\/sheets\//, '/');

    let url = `/views/${newContentUrl}`;

    if (siteContentUrl !== '' && siteContentUrl !== 'default' && siteContentUrl !== null) {
        url = `/t/${siteContentUrl}${url}`;
    }
    url = baseUrl + url;
    return url;
};

export const isAboutValid = about => {
    const requiredPayloadProps = ["tableau_site_user_id"],
        requiredTableauProps = ["rest_api_version", "site_id", "site_content_url"]

    // Validate payload
    for (const property of requiredPayloadProps) {
        if (!about.payload.hasOwnProperty(property) && !about.payload[property]) {
            return false;
        }
    }

    // Validate tableau server
    for (const property of requiredTableauProps) {
        if (!about.tableau_server.hasOwnProperty(property) && !about.payload[property]) {
            return false;
        }
    }

    return true;
}

export default {
    projectSort,
    viewSort,
    buildViewUrl,
    isAboutValid,
    generateProjectTree,
    removeDuplicates
};