const state = () => ({
    message: ""
});

const mutations = {
    message (state, payload) {
        state.message = payload;
    }
};

const getters = {
    hasError (state) {
        return !!state.message;
    }
};

const actions = {
    setError ({commit}, {message}) {
        commit('message', message);
    },
    clear () {
        commit('message', "");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};