import Vue from 'Vue';

const state = () => ({
    viz: null,
    filters: null, // Object
    isPaused: false,
});

const mutations = {
    viz (state, viz) {
        state.viz = viz;
    },
    filters (state, filters) {
        state.filters = filters;
    },
    isPaused (state, isPaused) {
        state.isPaused = isPaused;
    },
};

const getters = {};

const actions = {
    vizLoaded ({commit, dispatch}, viz) {
        const {TableauEventType} = Vue.tableauV3;
        viz.addEventListener(TableauEventType.FilterChanged, async event => {
            let filter = event.getFilterAsync();
            dispatch('tableauFilterChange', filter);
        });

        viz.addEventListener(TableauEventType.ParameterChanged, async event => {
            let param = await event.getParameterAsync();
            dispatch('tableauParamChange', param);
        });

        viz.addEventListener(TableauEventType.TabSwitched, event => {
            dispatch('tableauTabSwitch', event.newSheetName)
        });

        commit('viz', viz);
    },
    loadFilters ({commit}) {
        const params = new URLSearchParams(window.location.search);
        const filters = {};// {name:{type,values}},

        for (const param of params.entries()) {
            const key = param[0]; const
                value = param[1];
            if (Object.prototype.hasOwnProperty.call(filters, key)) {
                filters[key].push(value);
            } else {
                filters[key] = [value];
            }
        }
        commit('filters', filters);
    },
    // Tableau API
    downloadPdf ({state}) {
        const {TableauDialogType} = Vue.tableauV3;
        state.viz.displayDialogAsync(TableauDialogType.ExportPDF);
    },
    downloadImage ({state}) {
        state.viz.exportImageAsync();
    },
    downloadData ({state}) {
        const {TableauDialogType} = Vue.tableauV3;
        state.viz.displayDialogAsync(TableauDialogType.ExportData);
    },
    downloadCrossTab ({state}) {
        const {TableauDialogType} = Vue.tableauV3;
        state.viz.displayDialogAsync(TableauDialogType.ExportCrossTab);
    },
    downloadPowerPoint ({state}) {
        const {TableauDialogType} = Vue.tableauV3;
        state.viz.displayDialogAsync(TableauDialogType.ExportPowerPoint);
    },
    pause ({commit, state}) {
        state.viz.pauseAutomaticUpdatesAsync();
        commit('isPaused', true);
    },
    resume ({commit, state}) {
        state.viz.resumeAutomaticUpdatesAsync();
        commit('isPaused', false);
    },
    refresh ({state}) {
        state.viz.refreshDataAsync();
    },
    revert ({state}) {
        state.viz.revertAllAsync();
    },
    redo ({state}) {
        state.viz.redoAsync();
    },
    undo ({state}) {
        state.viz.undoAsync();
    },
    tableauTabSwitch ({dispatch, rootState}, newSheet) {
        let workbook = rootState.workbooks.workbook;
        let view = workbook.views.find(view => view.name === newSheet);
        dispatch('views/setView', view, {root: true});
    },
    tableauFilterChange ({dispatch}, filter) {
        const {FilterType} = Vue.tableauV3;
        let name = filter.fieldName,
            filterType = filter.filterType,
            paramsToApply = {}; //{name:[values]}

        switch (filterType) {
            case FilterType.Categorical: {
                if (filter.isAllSelected) {
                    paramsToApply[name] = [];
                } else {
                    paramsToApply[name] = filter.appliedValues.map(x => x.value);
                }
                break;
            }
        }
        dispatch('updateQueryString', paramsToApply);
    },
    tableauParamChange ({dispatch}, param) {
        let name = param.name,
            value = param.currentValue,
            paramsToApply = {}; //{name:[values]}
        paramsToApply[name] = [value.value]; //{name:[values]}

        dispatch('updateQueryString', paramsToApply);
    },
    updateQueryString ({ }, paramsToApply) {
        let params = new URLSearchParams(window.location.search);
        Object.keys(paramsToApply).forEach(paramName => {
            if (params.has(paramName)) {
                params.delete(paramName)
            }
            for (const entery of paramsToApply[paramName]) {
                params.append(paramName, entery);
            }
        })
        let querysString = params.toString()
        let newUrl = `${window.location.pathname}${querysString.length ? `?${querysString}` : ''}`;
        history.pushState({state: 'filtering'}, 'Filter', newUrl);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};