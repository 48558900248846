import * as api from '../../services/api.service';

const state = () => ({
    error: '',
    isLoggingIn: false,
    username: '',
    password: '',
    tableauServerSite: '',
    tableauServerUrl: '',
    required: {},
    label: {
        password: 'Password',
        username: 'Username',
        tableauServerSite: 'Tableau Server Site',
        tableauServerUrl: 'Tableau Server URL'
    },
});

const mutations = {
    error (state, err) {
        state.error = err;
    },
    isLoggingIn (state, loggingIn) {
        state.isLoggingIn = loggingIn;
    },
    username (state, user) {
        state.username = user;
    },
    password (state, pass) {
        state.password = pass;
    },
    tableauServerUrl (state, url) {
        state.tableauServerUrl = url;
    },
    tableauServerSite (state, site) {
        state.tableauServerSite = site;
    },
    required (state, property) {
        state.required = {
            ...state.required,
            [property]: true
        }
    }
};

const getters = {};

const actions = {
    login ({state, commit}) {
        commit('isLoggingIn', true);
        commit('error', null);
        const requiredProps = state.required;
        for (const key in requiredProps) {
            if (requiredProps[key] && !state[key]) {
                commit('isLoggingIn', false);
                commit('error', `${state.label[key]} value is required`);
                return;
            }
        }

        api.login(state.username, state.password, state.tableauServerUrl, state.tableauServerSite)
            .then(({location}) => {
                window.location.href = location;
            })
            .catch(error => {
                const errors = error.response?.data?.errors;
                if (errors) {
                    commit('error', errors[0].message);
                } else {
                    commit('error', 'Invalid username or password.');
                }
            })
            .finally(() => {
                commit('isLoggingIn', false);
            });
    },
    logout () {
        api.logout();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};