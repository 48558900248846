if (process.env.NODE_ENV !== 'test') {
    var APP_VERSION = "{{{__APP_VERSION__}}}"
    console.log(`Zuar Rapid Portal ${APP_VERSION.substring(3, APP_VERSION.length - 4)}`);
}

import {init} from '../services/api.service';

import Vue from 'Vue';
import Vuex from 'vuex';

// Modules
import loginModule from './modules/login';
import customizerModule from './modules/customizer';
import projectsModule from './modules/projects';
import templatesModule from './modules/templates';
import userModule from './modules/user';
import vizModule from './modules/viz';
import viewsModule from './modules/views';
import workbooksModule from './modules/workbooks';
import errorModule from './modules/error';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        login: loginModule,
        customizer: customizerModule,
        projects: projectsModule,
        templates: templatesModule,
        user: userModule,
        viz: vizModule,
        views: viewsModule,
        workbooks: workbooksModule,
        error: errorModule,
    },
    state () {
        return {
            about: null,
            isFullscreen: false,
        };
    },
    mutations: {
        about (state, about) {
            state.about = about;
        },
        isFullscreen (state, isFullscreen) {
            state.isFullscreen = isFullscreen;
        },
    },
    actions: {
        async loadAbout ({commit}) {
            let [data, error] = await init();
            if (error) return;
            commit('about', data);
            window.onpopstate = () => window.location.reload();
        },
    },
});


export default store;
